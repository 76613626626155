.stale {
    background-color: #374952;
    color: #fff;
}

.active {
    background-color: #7398ab;
    color: #fff;
}

.stale,
.active {
    box-shadow: var(--chip-shadow);
}
